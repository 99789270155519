import React from "react"
import SEO from '../components/seo'
import Layout from "../containers/layout/layout"
import Header from '../containers/layout/header'
import Footer from '../containers/layout/footer'
import CelestialTitleArea from '../containers/celestial/celestial-title-area'
import CelestialBannerArea from '../containers/celestial/celestial-banner-area'
import CelestialContentArea from '../containers/celestial/celestial-content-area'
import ArtworkArea from '../containers/global/artwork'
 
const CelestialPage = () => (
  <Layout>
    <SEO 
        title="Celestial Star" 
        description="Celestial Star Page description"
        keywords={["character", "design", "portfolio"]}
    />
    <Header/>
    <div className="main-content">
        <CelestialTitleArea/>
        <CelestialBannerArea/>
        <CelestialContentArea/>
        <ArtworkArea/>   
    </div>
    <Footer/>
  </Layout>
)

export default CelestialPage
